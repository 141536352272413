.tabs-hold-pushDown {
  display: flex !important;
  gap: 5% !important;
  justify-content: center !important;
  border-bottom: none !important;
  top: 28rem;
  position: relative;
}

.placeTabs-hold {
  display: flex;
}


@media (max-width : 1345px) {


  .single-page-hold {

    top: 21rem !important;
    left: 42rem !important;
    width: 41rem !important;
  }


  .single-page-main-pic {
    width: 38rem !important;
    top: 21rem !important;
    border-radius: 15px;
    left: 2rem !important;
  }


  .features-text {
    left: 4rem !important;
  }

  .actual-features {
    margin-top: 1%;
    left: 4rem !important;
    font-style: italic;

  }

  .line1 {
    left: 4rem !important;
    display: none;
  }

  .icon-hold {
    left: 5rem !important;
  }

  .heading-hold {
    left: 8.4rem !important;
  }

  .description-hold {
    left: 8.5rem !important;
    margin-top: 0.2%;
  }

  .side-box-card {
    left: 48rem !important;
  }

  .line2 {
    display: none;
  }

  .reserve-date-button-holder {
    left: 54rem !important;
  }

  .days-0 {
    left: 2rem !important;
    z-index: -1;
  }

  .expense-title-hold {
    left: 50rem !important;
  }

  .calculated-expense-hold {
    left: 65rem !important;
  }

  .line-total {
    left: 50rem !important;
  }

  .price-total-text {
    left: 51rem !important;
    top: 82.4rem !important;
  }

  .price-total {
    width: 20rem;
    top: 82.4rem !important;
    left: 58rem !important;
  }

  .rdrStaticRanges {
    left: 40rem !important;
  }

  .rdrDefinedRangesWrapper {
    left: -12rem !important;
    position: relative;
    background-color: transparent !important;

  }

  .rdrCalendarWrapper {
    left: -12rem !important;
    position: relative;
  }

  .close-cal {
    left: -2rem !important;
    top: 4.9rem !important;
    position: relative !important;

  }


  .tabs-hold2 {
    left: 40rem !important;
  }

  .book-heading {
    left: 4rem !important;
  }

  .place-datails-container {
    left: 2rem !important;
  }


  .paypalCardHold {
    left: -2.5rem !important;
    width: 37rem !important;
  }

  .paypalbghold {
    right: 4rem !important;
  }

  .credHold {
    right: -45rem !important;
  }

  .cc {
    width: 14rem !important;
  }


  .reciptFor {
    position: absolute !important;
    top: 7.8rem !important;
    font-size: 16px !important;
    left: -1.8rem !important;
    width: 7rem !important;

  }

  .figure-hold3 {
    position: absolute !important;
    top: 13rem !important;
    left: -0.6rem !important;
    gap: 3.4rem !important;
  }

  .heading-hold2 {
    position: absolute !important;
    top: 16.6rem !important;
    left: 0.2rem !important;
  }

  .confirmnum {
    position: absolute !important;
    top: 27.7rem !important;
    font-size: 16px !important;
    left:-0.5rem !important;
  }

  .stayAt2 {
    font-size: 17px !important;
  }

  .hello2 {
    position: absolute !important;
    top: 18.5rem !important;
    right: 10rem !important;
  }

  .confirmHold2 {
    top: 24.7rem !important;
    right: 10.8rem !important;
  }


  .calendarHolder2 {
    position: relative !important;
    left: 28rem !important;
  }


  .checkout-btn-after {
    margin-left: -11rem !important;
    margin-top: 2rem !important;
  }
}


@media (max-width:1050px) {
  .logo {
    font-size: 50px;
  }

  .txt {
    top: -3rem;
    left: 4rem;
    font-size: 20px;
  }


}

@media (max-width : 600px) {

  .tabs-hold {
    width: 100vw !important;
    left: 4.5rem !important;
  }

  .im {
    width: 1.4rem !important;
    position: absolute;
  }

  .tab1-hold {
    left: 18.4rem !important;

  }

  .card {
    margin-bottom: 4rem !important;
  }

  .basicDeatils {
    top: 25rem !important;
    position: absolute !important;
    left: 0rem !important;
    gap: 2rem !important;
    padding-left: 0.4rem;
    padding-right: 0.4rem;

  }


  .single-page-name {
    top: 24rem !important;
    font-size: 20px !important;
    left: 1rem !important;
    display: flex;
    flex-wrap: wrap;
    width: 18rem;
  }


  .single-page-review {
    top: 28rem !important;
    font-size: 20px !important;
    left: 1rem !important;
  }


  .single-page-star {
    top: 28.2rem !important;
    font-size: 20px !important;
    left: 4.5rem !important;

  }

  .single-page-rev-text {
    top: 50rem !important;
    display: none;
  }

  .super-host {
    top: 50rem !important;
    display: none;
  }

  .heart {
    top: 50rem !important;
    display: none;
  }

  .single-page-main-pic {
    top: 8rem !important;
    left: 0rem !important;
    width: 100vw !important;
    border-radius: 0 !important;
    height: 15rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

  }

  .single-page-hold {
    display: none !important;
  }

  .single-page-pic {
    width: 5rem !important;
    height: 5rem !important;
  }


  .features-text {
    top: 32rem !important;
    font-size: 17px;
    display: flex;
    flex-wrap: wrap;
    width: 17rem;
    left: 1rem !important;

  }

  .actual-features {
    top: 37rem !important;
    font-size: 17px;
    left: 1rem !important;
  }

  .icon-hold {
    top: 42rem !important;
    left: 1.5rem !important;
  }

  .heading-hold {
    top: 42rem !important;
    left: 6rem !important;

  }

  .description-hold {
    top: 44rem !important;
    left: 6rem !important;
    gap: 4rem !important;
    max-width: 95% !important;
    width: 74% !important;
    padding-right: 0.5rem;


  }

  .section1-hold {
    top: 69rem !important;
    left: 3rem !important;
    width: 18rem !important;

  }

  .spmLine {
    top: 64rem !important;
    left: 0rem !important;
    white-space: nowrap;

  }

  .spmLine2 {
    top: 87rem !important;
    left: 0rem !important;
    white-space: nowrap;


  }

  .section2-hold {
    top: 92rem !important;
    width: 19rem !important;
    left: 2rem !important;

  }

  .section3 {
    top: 118rem !important;
    width: 130vw !important;
    left: -3rem !important;
  }

  .spmLine3 {
    top: 114rem !important;
    left: 0rem !important;
    white-space: nowrap;

  }

  .c {
    position: relative;
    width: 120vw !important;
    height: 26vh !important;
    display: none;
  }


  /*  OTHER THINGS    */

  .spm-star {
    top: 200rem !important;
  }

  .spm-rev {
    top: 200rem !important;
  }

  .star-calc-hold {
    top: 220rem !important;
  }

  .review-hold {
    top: 220rem !important;
  }



  .side-box-card {
    left: 0.4rem !important;
    top: 121rem !important;
    width: 23rem !important;
    height: 34rem !important;
  }


  .single-page-price {
    font-size: 15px;
  }

  .ab {
    left: 9rem !important;
  }

  .rev-card {
    left: 10.7rem !important;
    font-size: 17px !important;
  }

  .rev2-card {
    white-space: nowrap;
    left: 13.7rem !important;
    font-size: 15px !important;
  }

  .reserve-date-button-holder {
    top: 128rem !important;
    left: 6rem !important;
  }

  .calendarHolder2 {
    left: -6rem !important;
    top: 37rem !important;
  }


  .rdrDefinedRangesWrapper {
    display: none;
  }

  .expense-title-hold {
    top: 137rem !important;
    left: 2.2rem !important;
  }

  .calculated-expense-hold {
    top: 134rem !important;
    left: 16rem !important;

  }

  /*  card details hold  */

  .days-0 {
    top: -3rem !important;
    left: -13rem !important;
  }

  .close-cal {
    top: 150rem !important;
    left: -13rem !important;
    top: 49% !important;
    margin-top: 15rem;
    position: absolute !important;
    bottom: 47rem !important;

    width: 9.6rem !important;
    font-size: 14px !important;
  }

  .checkout-btn-after {
    margin-left: -24rem !important;
    margin-top: 1rem !important;
    width: 9.6rem !important;
    font-size: 14px !important;

  }

  .le {
    font-size: 14px !important;
  }

  .line-total {
    top: 146.6rem !important;
    left: 0.2rem !important;
    white-space: nowrap;
  }

  .price-total-text {
    top: 145rem !important;
    position: relative;
    left: 2rem !important;
  }

  .price-total {
    top: 143.2rem !important;
    position: relative;
    left: 8rem !important;

  }

  .st {
    top: -20rem !important;
    position: relative;
    display: none;
  }

  .spm-rev {
    display: none;
  }

  .footer-pic {
    display: none;
  }

  .footerLine1 {

    display: none;
  }

  .footer-things {
    top: 169rem !important;
    left: 2rem !important;
  }



  .agg {
    top: 17rem !important;
    left: -3rem !important;
    display: none;
  }

  .modal-box {
    width: 5rem !important;
  }

  .spm-pic {
    display: none;
  }

  .footer2 {
    top: 199rem !important
  }



  .book-heading {
    white-space: nowrap;
    top: 9rem !important;
    left: 2rem !important;
  }

  .tabs-hold2 {
    top: 60rem !important;
    left: 2rem !important;
  }

  .paypalCardHold {
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    width: 36rem !important;
    left: -2rem !important;
    padding-bottom: 4rem !important;
  }



  .paypalLogo2 {
    right: -5.3rem !important;
  }

  .reciptFor {
    font-size: 14px !important;
    top: 8rem !important;
    left: -1.8rem !important;
    width: 6rem !important;
  }

  .figure-hold3 {
    top: 13rem !important;
    left: -0.6rem !important;
    gap: 3.4rem !important;
  }

  .val{
    width: 4.5rem !important;
    font-size: 14px;
  }

  .paypalcard{
    border-radius: 0 !important;
  }

  .confirmnum {
    top: 27.5rem !important;
    left: -1rem !important;

  }

  .heading-hold2 {
    top: 16.5rem !important;
    left: -0.3rem !important;

  }


  .stayAt2 {
    top: 13rem !important;
    left:6.2rem!important;
    font-size: 13px !important;
  }

  .totalCard2 {
    top: 15rem !important;
    left: 6.3rem;
    font-size: 14px;
    background: white !important;
    background-color: white !important;
    width: 10rem !important;

  }

  .confirmHold2 {
    top: 26rem !important;
    left: 6.1rem !important;
  }

  .whiteover {
    display: none !important;
  }



  .hello2 {
    top: 17.7rem !important;
    width: 10rem !important;
    font-size: 14px;
    left: 6.3rem !important;

  }

  .place-datails-container {
    left: 0rem !important;
  }

  .checkout-name {
    left: 15rem !important;
  }

  .checkout-stars {
    left: 15rem !important;
  }

  .checkout-start-price {
    left: 15rem !important;
  }

  .checkout-protected {
    font-size: 14px !important;
    left: 1.6rem !important;
  }

  .checkout-logo {
    width: 5rem;
    position: relative;
    left: 17rem !important;
  }

  .checkout-price-heading{
    left: 8rem !important;
  }

  .checkout-expense-title{
    left: 2rem !important;
  }

  .checkout-expense-calc{
    left: 17rem !important;
  }

  .checkout-toal-heading{
    position: relative;
    left: 3.84rem !important;
  }

  .total-checkout{
    left: 16rem !important;
  }

  .walHold {
    width: 10rem !important;
    top: 37rem !important;
    left: -33rem !important;

  }

  .credHold {
    width: 10rem !important;
    top: 47rem !important;
    left: -19rem !important;
  }

  .cc {
    height: 11rem !important;
  }

  .gpayHold {
    top: 2rem !important;
    left: 9.8rem !important;
    width: 10rem !important;
    height: 10rem !important;

  }




}




.tab-content {
  display: flex;
  justify-content: center;
  margin-top: 2%;

}

.css-1oezttv {
  color: black !important;

}


.tab-name-holder {
  display: flex;
  flex-direction: row;
  gap: 105px;
  font-weight: 500;
  position: relative;
  left: -0rem;
  position: relative;
  justify-content: center;
}


@media(max-width:600px) {
  .tabImg {
    padding-bottom: 0.19rem !important;
  }
}

.placeTabs-hold {
  display: flex !important;
  gap: 1% !important;
  justify-content: center !important;
  border-bottom: none !important;
  top: 0.5rem;
  position: relative;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  overflow: scroll !important;
  padding-bottom: 0.4rem;
}


@media(max-width:1000px) {
  .placeTabs-hold {
    justify-content: flex-start !important;
  }
}

.placeTabs-hold::-webkit-scrollbar {
  width: 1rem;
  height: 1rem;
  display: inline-block !important;
}



.placeTabs-hold::-webkit-scrollbar-track {
  width: 1rem !important;
  height: 1rem !important;
}

.placeTabs-hold::-webkit-scrollbar-thumb {
  width: 1rem !important;
  height: 1rem !important;
}