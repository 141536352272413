.gpayHold {
  position: absolute;
  top: 10rem;
  right: 6rem;
}

.credHold {
  position: absolute;
  top: -3rem;
  right: -51rem;
  width: 50rem;
}

.gpayHold {
  position: absolute;
  top: 5rem;
  right: 1rem;
}

.cc {
  width: 18rem;
  height: 14rem;
}

.walHold {
  position: absolute;
  right: 15rem;
}


.bn {
  font-size: 64px;
  position: absolute;
  top: -11rem;
  right: 66rem;
  color: #008080;
}


.gpayAsset1Hold {
  width: 34rem;

}


.googlePayButtonContainer {
  width: 32rem;

}


.gpayAsset2Hold {
  left: -11rem !important;
  position: relative !important;
}

@media(min-width:1250px) {
  .gpayAsset2Hold {
    position: relative !important;
    left: -9rem !important;
  }
}