body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  box-sizing: border-box;
  max-width: 100% !important;
}

img {
  max-width: 100% !important;
}

::-webkit-scrollbar {
  display: none;
}

/*
.slick-prev {
  left: -25px !important;
  width: 5rem !important;
  height: 5rem !important;
  top: 10rem !important;
  z-index: 999 !important;
  font-size: 30px !important;
}

.slick-next {
  left: 20rem !important;
  width: 5rem !important;
  height: 5rem !important;
  top: 10rem !important;
  z-index: 99999 !important;
  font-size: 30px !important;

}
*/

.card {
  overflow: visible !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  overflow-x: hidden !important;
}


.boxsh {
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
}

/*
.swiper-button-next, .swiper-button-prev{
  background: #ece6e6;
    color: black !important;
    width: 4.2rem !important;
    height: 4.2rem !important;
    border-radius: 50%;
}

*/
html,
body {
  height: 100% !important;
}


.close-cal2 {
  background-color: #008080;
  height: 3.5rem;
  width: 12rem;
  transition: 400ms;
  font-weight: 600;
  font-size: 18px;
  color: white;
  top: 9rem;
  position: relative;
  left: 1.4rem;
}

@media(min-width:1320px) {
  .calendNavBarHodl {
    position: relative;
    left: -7rem
  }
}


.swiper-pagination-bullet-active {
  background-color: white !important;
}




@tailwind base;
@tailwind components;
@tailwind utilities;