.css-jt91d7[aria-selected=true], .css-jt91d7[data-selected]{
    background-color: #008080 !important;
    color: white !important;
}

.tabs-hold2{
    position: absolute;
    top: 10.4rem;
    left: 50rem;
    width: 40rem;

}

.css-jt91d7{
    width: 10rem;
}

.css-a5mhaz{
    padding: var(--chakra-space-4);
    outline: 2px solid transparent;
    position: relative;
    outline-offset: 2px;
    top: 5rem;
   /* left: -17rem;*/
}